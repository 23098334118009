<template>
  <div class="box">
    <iframe class="lazy" :src="src" :height="bodyClientHeight" scrolling="auto" frameborder="0" id="iframe"></iframe>
    <!-- <img :src="src" class="img" id="iframe" alt="" > -->
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, onActivated } from 'vue'
import { useRoute } from 'vue-router'
import { Toast } from 'vant'
// import { DownloadPdfPic } from "@/api/api"

export default {
  components: {
  },
  setup () {
    const route = useRoute()
    const data = reactive({
      src: '',
      bodyClientHeight: document.body.clientHeight - 60,
    })

    onMounted(() => {
      try {
        Toast.loading({
          message: '图纸正在加载中...',
          forbidClick: true,
          duration: 0
        })
        const iframe = document.getElementById('iframe')
        console.log(route.params)
        const dataO = Object.values(route.params)
        // console.log(data0)
        const src = dataO.join("")

        data.src = './2d/preview.html?src=' + src
        // data.src = src
        setTimeout(() => {
          console.log(data.src, src)
        }, 3000)

        iframe.onload = function() {
          Toast.clear()
        }
      } catch (e) {
        console.log(e)
      }
    })

    // 每次都执行
    // onActivated(() => {
    //   Toast.loading({
    //     message: '图纸正在加载中...',
    //     forbidClick: true,
    //     duration: 0
    //   })
    //   const iframe = document.getElementById('iframe')
    //   const dataO = Object.values(route.query.k)
    //   const src = dataO.join("")
    //   console.log(src)
    //   data.src = './2d/preview.html?src=' + src
    //
    //   iframe.onload = function() {
    //     Toast.clear()
    //   }
    // })

    const methodsMap = {
    }
    return { ...toRefs(data), ...methodsMap, route }
  }
}
</script>
<style scoped lang="scss">
.box {
  // iframe{
  //   width: 100%;
  // }
  #iframe {

    width: calc(100vw);
    // height: 300px;
  }
  // img{
  //   width: 100vw;
  // }
}
</style>
